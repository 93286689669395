<template>
	<!-- Start Template Root Div -->
	<div>
		<WaveLoader v-if="searchSurfWaiversLoading" />

		<header class="w-full sticky top-0 z-10">
			<div class="w-full bg-gray-700">
				<div class="container mx-auto py-2 lg:p-8 lg:py-3">
					<div
						class="w-full flex justify-between items-center p-2 text-white"
					>
						<div class="text-white p-5">
							<WaveLogo
								class="cursor-pointer h-16 md:h-12 lg:h-16"
							/>
						</div>
						<div>
							<h1 class="text-2xl">
								Surf Ops - Customer Check-In
							</h1>
						</div>
						<div>
							<p class="m-0">
								{{ new Date() | moment("dddd Do MMMM") }}
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>

		<main class="container mx-auto py-2 lg:p-8 lg:py-3">
			<section class="flex flex-col text-sm mb-20">
				<div class="w-full h-full relative">
					<div class="rounded shadow-lg bg-white p-4 mb-8">
						<h2 class="text-xl mb-2">
							Acknowledgement Of Risk And Waiver
						</h2>
						<p class="mb-2">
							Please Read Carefully Before Completing:
						</p>
						<p class="mb-2">
							It is important that you read and understand this
							acknowledgement of risk and waiver before completing
							it. You will not be permitted to take part in any
							surf session or other activity in the lake until it
							has been completed. If you will be under 18 years
							old on the day of your booked activity, the waiver
							must be completed by your parent or legal guardian.
						</p>
						<p class="mb-2">
							If there is anything that you do not understand
							please get in touch with our Customer Service Team
							on
							<a href="tel:03330164133" class="text-dark"
								>0333 016 4133</a
							>
						</p>
						<h3>Acknowledgement Of Risk And Waiver</h3>
						<p class="mb-2">
							Surfing and bodyboarding are physically challenging
							and carry risks that we cannot entirely eliminate,
							including damage to property, personal injury and in
							extremely rare circumstances, even fatalities. You
							will reduce these risks by following The Wave Safety
							Rules set out below and all the instructions given
							to you by The Wave Staff.
						</p>
						<p class="mb-2">
							The Wave can produce a variety of waves of different
							speeds, shapes and sizes, which allows us to provide
							a safe environment for all abilities, from beginners
							to professionals. Information and videos about the
							types of waves in each session and any specific
							risks or safety rules relating to the session are
							available on our website. The sessions are graded by
							ability. It is your responsibility to read the
							session descriptions , watch the videos provided and
							to ensure that you are competent to take part safely
							in your chosen session. If, during a session, we
							feel that you are not competent and may put yourself
							or others at risk, we reserve the right to remove
							you from the session without refund or compensation.
						</p>
						<p class="mb-2">
							We strongly advise that you begin with the easier
							sessions and work your way up. If you have any
							questions at all about the level of skill required
							for a session, please ask a member of The Wave Staff
							or contact our Customer Service Team.
						</p>
						<h3>Disclaimer</h3>
						<ul class="list-disc my-5 ml-5">
							<li>
								<p class="mb-2">
									In the absence of any negligence or other
									breach of duty by us:
								</p>
								<ul class="list-disc my-5 ml-5">
									<li class="mb-2">
										Your use of our equipment, facility and
										participation in any activity while at
										The Wave is entirely at your own risk.
									</li>
									<li class="mb-2">
										We are not responsible for any theft,
										damage, destruction or loss of your
										property or belongings while using our
										facilities.
									</li>
									<li class="mb-2">
										We accept no liability for any direct or
										indirect loss, damage or injury arising
										from or in connection with your
										participation in any activity at The
										Wave.
									</li>
								</ul>
							</li>
						</ul>
						<h3>The Wave Safety Rules:</h3>
						<ul class="list-disc my-5 ml-5">
							<li class="mb-2">
								You must behave sensibly and follow all safety
								instructions so as not to hurt or injure
								yourself or others.
							</li>
							<li class="mb-2">
								We can provide you with the equipment you need
								to safely enjoy your surf session. It is your
								responsibility to use all equipment safely and
								as directed.
							</li>
							<li class="mb-2">
								You must attend the safety briefing before
								participating in any activity in the lake and
								raise any concerns or lack of understanding with
								The Wave Staff.
							</li>
							<li class="mb-2">
								You must read the session descriptions, watch
								the videos and respect the ability gradings,
								even if you have taken part in a session before.
								We make ongoing changes and improvements to our
								sessions, so they may have changed since your
								last visit. You must not take part in a session
								that is beyond your current level of ability.
							</li>
							<li class="mb-2">
								You must be aware of the varying ability of
								other people in the lake. Be friendly, courteous
								and respectful of other people. Poor conduct
								will not be tolerated.
							</li>
							<li class="mb-2">
								We strongly recommend that you do not
								participate in any surfing session at The Wave
								if you are pregnant as it may cause harm to you
								or your unborn child. If you are pregnant and
								choose to participate, you do so at your own
								risk.
							</li>
							<li class="mb-2">
								You must not participate if you are under the
								influence of alcohol or non-prescription drugs
								or if you are taking any medication that may
								impair your physical ability.
							</li>
							<li class="mb-2">
								You must follow all safety warnings or
								instructions displayed or given to you by a
								member of The Wave Staff. Please ask a member of
								The Wave Staff if you are unclear.
							</li>
							<li class="mb-2">
								You must be able to swim at least 25 metres
								unaided.
							</li>
							<li class="mb-2">
								We are not qualified to express an opinion that
								you are fit to participate safely. If you suffer
								from any medical condition, illness or injury
								which may affect your ability to participate in
								surfing, bodyboarding or any other activities at
								The Wave, we recommend you obtain professional
								or specialist advice from your doctor and
								contact our Customer Services Team on
								<a href="tel:03330164133" class="text-dark"
									>0333 016 4133</a
								>
								to discuss your specific requirements.
							</li>
							<li class="mb-2">
								You agree to comply fully with the rules set out
								in our Booking Terms and Conditions.
							</li>
							<li class="mb-2 font-bold">
								If your ability to participate safely is
								affected or changes at any time after completing
								the waiver you must notify a member of The Wave
								Staff or contact the Customer Services Team on
								<a href="tel:03330164133" class="text-dark"
									>0333 016 4133</a
								>
							</li>
						</ul>
						<h3>Photography</h3>
						<p class="mb-2">
							We may take photographs or recordings during your
							visit to The Wave where you may be captured.
						</p>
						<p class="mb-2">
							You agree to the publication of such photographs and
							recordings, and their use by us and those authorised
							by us, in any way which we may see fit.
						</p>
						<p class="mb-2">
							This includes making photographs or recordings of
							surf or bodyboarding sessions available for purchase
							by you, via download from our website.
						</p>
						<p class="mb-2">
							If you do not wish to be filmed or photographed
							during your visit, including during your surf or
							bodyboarding session, please let a member of The
							Wave Staff know in advance.
						</p>
					</div>
				</div>
				<div class="w-full px-2">
					<div class="p-2">
						<div class="mb-10">
							<p class="font-bold">I confirm that:</p>
							<ul class="list-disc my-5 ml-5">
								<li class="mb-2">
									I will be 18 years or older on the date of
									my booked activity.
								</li>
								<li class="mb-2">
									I have read and understood The Wave Safety
									Rules and agree to follow them and the
									instructions of The Wave Staff during my
									booked activity and while on site.
								</li>
								<li class="mb-2">
									I have read this Acknowledgement of Risk and
									Waiver. I confirm that to the best of my
									knowledge I am fit to take part in the
									booked activity and acknowledge and accept
									the risks of such participation. If at any
									time my ability to take part is affected, I
									will cease participation and notify a member
									of The Wave Staff.
								</li>
								<li class="mb-2">
									I understand that The Wave may take
									photographs or recordings where I may be
									captured, including photographs or
									recordings of my surfing or bodyboarding
									session. I accept that these photographs or
									recordings may be available to purchase and
									download via The Wave website. I agree that
									if I do not wish to be filmed or
									photographed, I will let a member of The
									Wave Staff know in advance of my session.
								</li>
							</ul>
							<p class="font-bold">
								Name Of Participant: {{ over18sNames }}
							</p>
						</div>
						<div class="mb-10" v-if="under18s.length > 0">
							<p class="mb-2">
								I'm also completing this on behalf of
								individuals under the age of 18:
							</p>
							<p class="font-bold">I confirm that:</p>
							<ul class="list-disc my-5 ml-5">
								<li class="mb-2">
									I have read and understood The Wave Safety
									Rules. I will ensure that the participant
									understands and follows The Wave Safety
									Rules and the instructions of The Wave Staff
									during their booked activity and while on
									site.
								</li>
								<li class="mb-2">
									I have read this Acknowledgement of Risk and
									Waiver. I confirm that the participant is
									able to participate in the booked activity
									and I acknowledge and accept the risks of
									such participation. If at any time the
									participant's ability to participate is
									affected, they will cease participation and
									notify a member of The Wave Staff.
								</li>
								<li class="mb-2">
									I confirm that I am 18 years or older and
									that either (i) I am the parent or legal
									guardian of the participant, or (ii) if I am
									not the parent or legal guardian of the
									participant, I have the authority of the
									parent or legal guardian of the participant
									to sign this Acknowledgement of Risk and
									Waiver on their behalf.
								</li>
								<li class="mb-2">
									I understand that The Wave may take
									photographs or recordings where the
									participant may be captured, including
									photographs or recordings during a surfing
									or bodyboarding session. I accept that these
									photographs or recordings may be available
									to purchase and download via The Wave
									website. I agree that if I do not wish the
									participant to be filmed or photographed, I
									will let a member of The Wave Staff know in
									advance of my session.
								</li>
							</ul>
							<p class="font-bold">
								Name Of Participant(s): {{ under18sNames }}
							</p>
						</div>
					</div>
				</div>
			</section>
			<footer class="w-full fixed bottom-0 left-0 z-10 h-20">
				<div
					class="h-full p-4 flex items-center justify-center bg-white"
				>
					<button
						class="inline-flex bg-pear-500 hover:bg-pear-700 hover:border-0 text-sherpa-500 text-center text-sm font-bold py-2 px-3 rounded-full"
						@click="acceptWaviers"
					>
						Accept &amp; Continue
					</button>
				</div>
			</footer>
		</main>
	</div>
</template>
<script>
	import { mapState, mapActions } from "vuex";

	import WaveLogo from "@/components/WaveLogo.vue";
	import WaveLoader from "@/components/WaveLoader.vue";

	export default {
		components: {
			WaveLogo,
			WaveLoader
		},
		created() {
			if (this.searchSurfWaiversActiveUser === null) {
				this.$router.push({ name: "SurfOps/CheckIn" });
			}
		},
		computed: {
			...mapState("surfers", [
				"searchSurfWaiversLoading",
				"searchSurfWaiversResults",
				"searchSurfWaiversSuccess",
				"searchSurfWaivers",
				"searchSurfWaiversActiveUser"
			]),
			activeDate() {
				return this.$moment()
					.utc()
					.startOf("day")
					.toISOString();
			},
			under18s() {
				return this.searchSurfWaivers.filter(
					surfer =>
						surfer.age < 18 &&
						surfer.onSite === true &&
						surfer.hasSignedWaiver === false &&
						!(
							surfer.sessionNames.toLowerCase() ===
								"spectator admission" ||
							surfer.sessionNames.toLowerCase() ===
								"under 6 spectator admission"
						)
				);
			},
			over18s() {
				return this.searchSurfWaivers.filter(
					surfer =>
						surfer.age >= 18 &&
						surfer.onSite === true &&
						surfer.hasSignedWaiver === false &&
						!(
							surfer.sessionNames.toLowerCase() ===
								"spectator admission" ||
							surfer.sessionNames.toLowerCase() ===
								"under 6 spectator admission"
						)
				);
			},
			over18sNames() {
				return this.over18s
					.map(surfer => {
						return `${surfer.firstName} ${surfer.lastName}`;
					})
					.join(", ");
			},
			under18sNames() {
				return this.under18s
					.map(surfer => {
						return `${surfer.firstName} ${surfer.lastName}`;
					})
					.join(", ");
			}
		},
		methods: {
			...mapActions([
				"surfers/searchSurfersWaivers",
				"surfers/staticUpdateSurfersWaiver"
			]),
			async acceptWaviers() {
				// Wait for the looping of the Surfers to finish
				await this.$asyncForEach(
					this.searchSurfWaivers,
					async surfer => {
						if (surfer.onSite === true) {
							surfer.hasSignedWaiver = true;

							// Get the Fresh API Objects
							let serverObjects = await this
								.searchSurfWaiversResults[surfer.firstName];

							// Wait for the looping of them to finish
							await this.$asyncForEach(
								serverObjects,
								async obj => {
									// Update the Account
									await this.$accountsAPI.updateAccountPartial(
										{
											uuid: obj.account,
											waiver_last_signed: new Date().toISOString()
										}
									);

									// Update the Waiver
									obj.hasSignedWaiver = true;
									obj.activeDate = this.activeDate;
									await this[
										"surfers/staticUpdateSurfersWaiver"
									](obj);
								}
							);
						}
					}
				);

				// Check Video Parameter
				if (this.$route.query.showVideo == true)
					this.$router.push({
						name: "SurfOps/CheckInVideo",
						query: {
							showVideo: this.$route.query.showVideo,
							showDisclaimer: false
						}
					});
				// Complete
				else this.$router.push({ name: "SurfOps/CheckInComplete" });
			}
		}
	};
</script>
<style lang="scss" scoped>
	ul {
		padding: inherit;
	}
</style>
